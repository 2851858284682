// extracted by mini-css-extract-plugin
export var tempFlexDisplayFonts = "footer-module--tempFlexDisplayFonts--3n2tz";
export var tempFlexDisplayFontsSpacer = "footer-module--tempFlexDisplayFontsSpacer--3Dp_3";
export var bottomSelector = "footer-module--bottomSelector--1Aqb2";
export var bottomColumn = "footer-module--bottomColumn--1Pm6B";
export var bottomColumnHeader = "footer-module--bottomColumnHeader--uhuiB";
export var bottomRedDivider = "footer-module--bottomRedDivider--1w7jZ";
export var bottomFooter = "footer-module--bottomFooter--3MUOf";
export var bottomFooterA = "footer-module--bottomFooterA--BUAsQ";
export var bottomFooterAFontAwesome = "footer-module--bottomFooterAFontAwesome--sMSB2";
export var bottomAbsoluteFooter = "footer-module--bottomAbsoluteFooter--2JWcg";