import * as React from 'react';
import * as FooterStyle from './footer.module.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
   return (
      <>
         <div className={FooterStyle.bottomAbsoluteFooter}>
            <br />
            <br />
            <div className={FooterStyle.bottomSelector}>
               <div className={FooterStyle.bottomColumn}>
                  <div className={FooterStyle.bottomColumnHeader}>
                     Friendships
                  </div>
                  <div>
                     <a
                        href="https://www.disney.com"
                        className={FooterStyle.bottomFooterA}
                     >
                        {' '}
                        Friend
                     </a>
                  </div>
                  <div>
                     <a
                        href="https://www.disney.com"
                        className={FooterStyle.bottomFooterA}
                     >
                        {' '}
                        Friend
                     </a>
                  </div>
                  <div>
                     <a
                        href="https://www.disney.com"
                        className={FooterStyle.bottomFooterA}
                     >
                        {' '}
                        Friend
                     </a>
                  </div>
               </div>
               <div className={FooterStyle.bottomColumn}>
                  <div className={FooterStyle.bottomColumnHeader}>
                     Resources
                  </div>
                  <div>
                     <a
                        href="https://www.disney.com"
                        className={FooterStyle.bottomFooterA}
                     >
                        Books
                     </a>
                  </div>
                  <div>
                     <a
                        href="https://www.disney.com"
                        className={FooterStyle.bottomFooterA}
                     >
                        {' '}
                        Videos
                     </a>
                  </div>
                  <div>
                     <a
                        href="https://www.disney.com"
                        className={FooterStyle.bottomFooterA}
                     >
                        {' '}
                        Articles
                     </a>
                  </div>
               </div>
               <div className={FooterStyle.bottomColumn}>
                  <div className={FooterStyle.bottomColumnHeader}>
                     Partnerships
                  </div>
                  <div>
                     <a
                        href="https://www.disney.com"
                        className={FooterStyle.bottomFooterA}
                     >
                        {' '}
                        Patreon
                     </a>
                  </div>
                  <div>
                     <a
                        href="https://www.disney.com"
                        className={FooterStyle.bottomFooterA}
                     >
                        {' '}
                        Ko-fi
                     </a>
                  </div>
                  <div>
                     <a
                        href="https://www.disney.com"
                        className={FooterStyle.bottomFooterA}
                     >
                        {' '}
                        Paypal
                     </a>
                  </div>
               </div>
            </div>
            <br />
            <div className={FooterStyle.bottomRedDivider}></div>
            <div className={FooterStyle.bottomFooter}>
               {/* <div>twitter, facebook, instagram, youtube</div> */}
               {/* <i class="fab fa-twitter"></i>
            <i class="fab fa-facebook"></i>
            <i class="fab fa-instagram"></i>
            <i class="fab fa-youtube"></i> */}
               {/* HI hi
            <FontAwesomeIcon icon="check-square" />
            <FontAwesomeIcon icon={['fab', 'apple']} />
            <FontAwesomeIcon icon="check-square" />
            <FontAwesomeIcon icon={'coffee'} /> */}
               <div className={FooterStyle.tempFlexDisplayFonts}>
                  {/* <div className={FooterStyle.tempFlexDisplayFontsSpacer}>
                  <FontAwesomeIcon icon={['fab', 'github-square']} />
               </div> */}
                  <div className={FooterStyle.tempFlexDisplayFontsSpacer}>
                     <a
                        href="https://twitter.com"
                        className={FooterStyle.bottomFooterAFontAwesome}
                     >
                        <FontAwesomeIcon icon={['fab', 'twitter']} />
                     </a>
                  </div>
                  <div className={FooterStyle.tempFlexDisplayFontsSpacer}>
                     <a
                        href="https://twitter.com"
                        className={FooterStyle.bottomFooterAFontAwesome}
                     >
                        <FontAwesomeIcon icon={['fab', 'facebook']} />
                     </a>
                  </div>
                  <div className={FooterStyle.tempFlexDisplayFontsSpacer}>
                     <a
                        href="https://twitter.com"
                        className={FooterStyle.bottomFooterAFontAwesome}
                     >
                        <FontAwesomeIcon icon={['fab', 'instagram']} />
                     </a>
                  </div>
                  <div className={FooterStyle.tempFlexDisplayFontsSpacer}>
                     <a
                        href="https://twitter.com"
                        className={FooterStyle.bottomFooterAFontAwesome}
                     >
                        <FontAwesomeIcon icon={['fab', 'youtube']} />
                     </a>
                  </div>
               </div>
               <div>©️ 2021 Ecstaticism</div>
            </div>
         </div>
      </>
   );
};

export default Footer;
